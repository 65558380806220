import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn, cv } from "../../utils";

const [buttonVariants, buttonConfig] = cv(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      size: {
        default: "h-10 px-4 py-2 gap-2",
        sm: "h-9 rounded-md px-3 gap-2",
        lg: "h-11 rounded-md px-8 gap-3 text-base",
        icon: "h-9 w-9",
      },
      variant: {
        default: "hover:bg-primary-hover bg-primary font-bold text-primary-foreground",
        destructive: "hover:bg-destructive-hover bg-destructive text-destructive-foreground",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "hover:bg-secondary-hover bg-secondary text-secondary-foreground",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline px-1",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  } as const
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants, buttonConfig };
